import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import Popup from '../Popup';
import { useSelector } from 'react-redux';
import EmailGiftCard from '../EmailGiftCard';

const ModalGiftCard = ({ cardId, onModalClose }) => {
  const [card, setCard] = useState();
  const [product, setProduct] = useState();
  const products = useSelector((state) => state.productsStorage.gifts);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const request = await fetch(`/jsonapi/gift_cards/ecard/${cardId}`);
        const body = JSON.parse(request.body);
        const card = body.data.attributes;
        if (card.field_product_uuid && products[+card.field_product_uuid]) {
          setCard({
            message: card.field_message,
            name: card.field_recipient_name,
          });
          setProduct(products[+card.field_product_uuid]);
        }
      } catch (e) {
        // Don't display Ecard in case of any error.
        onModalClose();
      }
    };
    fetchCard();
  }, []);

  if (!card || !product) {
    return null;
  }

  return (
    <div>
      <Popup onRequestClose={onModalClose} isOpen={true}>
        <EmailGiftCard product={product} card={card} />
      </Popup>
    </div>
  );
};

ModalGiftCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default ModalGiftCard;
