import React from 'react';
import PropTypes from 'prop-types';
import DefaultPicture from '../../components/01_atoms/DefaultPicture';
import FormattedText from '../../components/01_atoms/FormattedText';

const EmailGiftCard = ({ product, card }) => {
  const friendName = card.name ? card.name : '...';
  // Fallback to default copy if e-card is not configured for this gift.
  const summary = `I've got you a very special gift - ${product.title}!`;

  const body = product.fieldEcardPreviewBody;

  // Fallback to default product image.
  const image = product.ecardPreviewImage ? product.ecardPreviewImage : product.image;

  return (
    <div className="email-card">
      <h1>Dear {friendName}</h1>
      <p className="card-intro">{summary}</p>
      {image && (
        <div className="email-card-image-wrapper">
          <DefaultPicture className="email-card-image" {...image} />
        </div>
      )}
      {body && <FormattedText className="card-body" text={body} />}
      <p className="email-card-message white-space-pre-wrap">{card.message}</p>
    </div>
  );
};

EmailGiftCard.propTypes = {
  product: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
};

export default EmailGiftCard;
